import IAction from "interfaces/IAction";
import IFeedsState from "interfaces/IFeedsState";
import IStandardFeed from "interfaces/IStandardFeed";

import {
  ADD_NEW_STANDARD_FEED,
  ADD_STANDARD_FEED_TO_STANDARD_FEEDS_TO_UPDATE,
  CLEAR_STANDARD_FEEDS,
  COPY_SELECTED_STANDARD_FEED,
  EDIT_STANDARD_FEEDS_TO_ADD,
  REMOVE_STANDARD_FEED,
  UPDATE_STANDARD_FEEDS,
  SET_STANDARD_FEEDS,
  UPDATE_SELECTED_STANDARD_FEED,
  UPDATE_STANDARD_FEEDS_AFTER_CALCULATION,
} from "./standardFeedsActions";

function updateSelectedStandardFeed(
  standardFeedsState: IFeedsState<IStandardFeed>,
  action: IAction
): IFeedsState<IStandardFeed> {
  if (action.payload) {
    return { ...standardFeedsState, selectedFeed: action.payload };
  }

  return { ...standardFeedsState, selectedFeed: undefined };
}

function updateStandardFeeds(
  standardFeedsState: IFeedsState<IStandardFeed>,
  action: IAction
): IFeedsState<IStandardFeed> {
  return { ...standardFeedsState, feeds: action.payload };
}

function updateStandardFeedsState(standardFeedsState: IFeedsState<IStandardFeed>, action: IAction) {
  return { ...standardFeedsState, ...action.payload };
}

function updateStandardFeedsAfterCalculation(
  standardFeedsState: IFeedsState<IStandardFeed>,
  action: IAction
): IFeedsState<IStandardFeed> {
  const feedToUpdate: IStandardFeed = action.payload.feeds;
  const feedsTemp: IStandardFeed[] = [...standardFeedsState.feeds];
  const indexOfFeed = feedsTemp.findIndex((feed) => feed.id === feedToUpdate.id);
  const { sortOrder } = action.payload;
  const indexOfFeedSortOrder = sortOrder.findIndex(
    (feed: IStandardFeed) => feed.id === feedToUpdate.id
  );
  if (indexOfFeed !== -1) {
    feedsTemp.splice(indexOfFeed, 1, feedToUpdate);
    /**
     * If we have a sortOrder we update the sortOrder array with the new feed.
     */
    if (sortOrder && indexOfFeedSortOrder) {
      sortOrder.splice(indexOfFeedSortOrder, 1, feedToUpdate);
    }
  }

  /**
   * If we have a sortOrder we sort the feeds array based on that before returning.
   */
  if (sortOrder) {
    feedsTemp.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
  }

  return { ...standardFeedsState, feeds: feedsTemp };
}

/**
 * @description Reducer method
 * @param {IStandardFeedsState} StandardFeedsState
 * @param {IAction} action
 * @return {*}{IStandardFeedsState}
 */
function standardFeedsReducer(
  standardFeedsState: IFeedsState<IStandardFeed>,
  action: IAction
): IFeedsState<IStandardFeed> {
  const args: [IFeedsState<IStandardFeed>, IAction] = [standardFeedsState, action];

  switch (action.type) {
    case UPDATE_STANDARD_FEEDS:
      return updateStandardFeeds(...args);

    case UPDATE_SELECTED_STANDARD_FEED:
      return updateSelectedStandardFeed(...args);

    case ADD_STANDARD_FEED_TO_STANDARD_FEEDS_TO_UPDATE:
    case ADD_NEW_STANDARD_FEED:
    case CLEAR_STANDARD_FEEDS:
    case EDIT_STANDARD_FEEDS_TO_ADD:
    case COPY_SELECTED_STANDARD_FEED:
    case REMOVE_STANDARD_FEED:
    case SET_STANDARD_FEEDS:
      return updateStandardFeedsState(...args);
    case UPDATE_STANDARD_FEEDS_AFTER_CALCULATION:
      return updateStandardFeedsAfterCalculation(...args);
    default:
      return standardFeedsState;
  }
}

export default standardFeedsReducer;
